<template>
  <CModal id="LogisticsOrderInfo" class="ActionModel" :show.sync="Toggle" :centered="true" @update:show="HandleHideModal">
    <template v-if="!Loading">
      <div class="mb-2 row">
        <label class="font-weight-bold col-md-4">物流單號</label>
        <div class="col-md-8">{{ Data.LogisticsData.TradeNo }}</div>
      </div>
      <div class="mb-2 row">
        <label class="font-weight-bold col-md-4">物流貨號</label>
        <div class="col-md-8">{{ Data.LogisticsData.BookingNo || Data.LogisticsData.TradeNo }}</div>
      </div>
      <div class="mb-2 row">
        <label class="font-weight-bold col-md-4">金流台訂單號</label>
        <div class="col-md-8">{{ Data.LogisticsData.OrderNo }}</div>
      </div>
      <div class="mb-2 row">
        <label class="font-weight-bold col-md-4">平台端訂單號</label>
        <div class="col-md-8">{{ Data.LogisticsData.MerchantOrderNo }}</div>
      </div>
      <div class="mb-2 row">
        <label class="font-weight-bold col-md-4">配送系統</label>
        <div class="col-md-8">{{ Data.LogisticsData.System }}</div>
      </div>
      <div class="mb-2 row">
        <label class="font-weight-bold col-md-4">配送方式</label>
        <div class="col-md-8">{{ Data.LogisticsData.Method }}</div>
      </div>
      <div class="mb-2 row">
        <label class="font-weight-bold col-md-4">包裹數量</label>
        <div class="col-md-8">{{ Data.LogisticsData.Quantity }} (PCS)</div>
      </div>
      <div class="mb-2 row">
        <label class="font-weight-bold col-md-4">重量</label>
        <div class="col-md-8">{{ Data.LogisticsData.Weight }} (KG)</div>
      </div>
      <div v-if="Data.LogisticsData.LabelImage && Data.LogisticsData.LabelImage !== 'Not supported currently.'" class="row">
        <label class="font-weight-bold col-md-4 mb-3">標籤</label>
        <div class="col-md-8">
          <a :href="Data.LogisticsData.LabelImage" target="_blank">下載</a>
        </div>
      </div>
    </template>
    <CElementCover v-if="(Loading === true)" :opacity="0.75" class="py-5">
      <CSpinner color="info"/>
    </CElementCover>
    <template #header-wrapper>
      <header class="modal-header border-bottom-0">
        <h5 class="modal-title">{{ $t('Order.LogisticsOrderInfo') }}</h5>
        <CButtonClose @click="HandleHideModal()" />
      </header>
    </template>
    <template #footer-wrapper>
      <footer class="modal-footer border-top-0">
        <CButton class="w-100" @click="HandleHideModal()" color="light">{{ $t('Global.Close') }}</CButton>
      </footer>
    </template>
  </CModal>
</template>

<script>
export default {
  name: "LogisticsOrderInfo",
  props: {
    Toggle: {
      type: Boolean,
      default: false
    },
    OrderNum: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      LogisticsFeatures: [],
      Loading: false,
      Data: {
        Payment: {},
        Logistics: {},
        Invoice: {},
        MemberInfo: {},
        Variable: {},
        ProductInfo: [],
        LogisticsData: {}
      }
    }
  },
  computed: {
    TemperatureOptions () {
      return Object.keys(this.$t('Logistics.TemperatureOptions')).map(status => {
        return {
          value: status,
          label: this.$t('Logistics.TemperatureOptions')[status]
        }
      })
    },
    DistanceOptions () {
      if (this.Data.Logistics.System) {
        return []
      } else {
        return Object.keys(this.$t('Order.LogisticsOrderOption.' + this.Data.Logistics.System + '.Distance')).map(status => {
          return {
            value: status,
            label: this.$t('Order.LogisticsOrderOption.' + this.Data.Logistics.System  + '.Distance')[status]
          }
        })
      }
    }
  },
  mounted() {
    this.LogisticsFeatures = this.$store.state.user.permission.Features.System.Logistics || []
  },
  watch: {
    Toggle (value) {
      if (value) this.GerOrder()
    }
  },
  methods: {
    HandleHideModal () {
      this.$emit('update:Toggle', false)
      this.LogisticsData = {
        MemberInfo: {}
      }
      this.Data = {
        Payment: {},
        Logistics: {},
        Invoice: {},
        MemberInfo: {},
        Variable: {},
        ProductInfo: [],
        LogisticsData: {}
      }
    },
    GerOrder () {
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/order/detail',
        method: 'get',
        params: {
          orderNum: this.OrderNum
        }
      }).then(({data}) => {
        this.Loading = false
        this.Data = data.detail
      }).catch((error) => {
        this.Loading = false
        throw error
      })
    }
  }
}
</script>

<style lang="scss">
#LogisticsOrderInfo {
  label {
    font-weight: bold;
  }
}
</style>
